<template>
  <v-footer color="#0e2771" style="color: #fcfdfd;">
    <v-container fluid>
      <v-row class="app-max-width" style="margin: 0 auto;">
        <v-col cols="12" cols-sm="6" cols-md="4" cols-lg="3">
          <router-link to="/">
            <img src="@/assets/digital-boop-long-format-logo-green.svg" alt="Boop Logo" class="boop-logo" height="auto" width="250px"
              style="margin-top: 8px;">
          </router-link>
          <p class="" style="font-size:19px; margin-left: 8px !important; font-weight: bold; margin-bottom: 16px;">
            Creative Clicks, Endless
            Possibilities</p>
          <p class="text-left" style="margin-left: 8px !important;">
            <b>digital boop.</b> is a digital marketing and design agency that specialises in web design and development, SEO and
            web content creation, logo design and social media marketing services, copywriting, and graphic design. We
            offer personalised social media marketing strategies tailored to your unique business needs.
          </p>
        </v-col>
        <v-col cols="12" cols-sm="6" cols-md="4" cols-lg="3">
          <router-link to="/privacy-policy" style="color: #fcfdfd; text-decoration: none;">
            <p style="margin-left: 8px !important; text-decoration: underline; font-size: 19px;">
              Privacy Policy
            </p>
          </router-link>
        </v-col>
        <v-col cols="12" cols-sm="6" cols-md="4" cols-lg="3">
          <p class="text-left" style="width: 100%; margin-left: 8px !important;">© 2023 DIGITAL BOOP. LTD, All rights
            reserved.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { openFacebook } from "@/helpers.js";

export default {
  name: "AppFooter",
  methods: {
    openFacebook
  }
};
</script>

<style scoped>
.text-left{
   font-size: 19px;
}
.boop-logo {
  margin-bottom: 16px;
}
@media (max-width: 959px) {
  .text-md-right {
    text-align: left !important;
  }
}
</style>

<!-- <v-col cols="12" cols-sm="6" cols-md="4" cols-lg="3" class="text-center text-md-right">
          <v-btn dark icon @click="openFacebook">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
        </v-col> -->
