<template>
    <div class="newsletter-subscription">
        <h2 class="headline">Subscribe to Our Newsletter</h2>
        <p class="subheadline">🚀 Get exclusive insights, 💡 tips, and 📈 industry trends to grow your business and enhance your online presence! 🌐</p>
        <v-form @submit.prevent="submitNewsletterForm" ref="newsletterForm"
            style="max-width: 600px !important; margin: 0 auto;">
            <span style="display: flex; flex-direction:row;" class="form-label">
                <v-text-field v-model="newsletterEmail" label="Your email address" required type="email"
                    :rules="[required, validEmail]"></v-text-field>
                <v-btn type="submit" :loading="sendingNewsletterForm" :disabled="!isFormValid"
                    class="primary-cta">
                    SUBSCRIBE
                </v-btn>
            </span>
            <v-checkbox v-model="gdprConsent" required :rules="[required]">
                <template v-slot:label>
                    <span style="margin-left: 8px">I have read and understood the <a style="font-size: 19px;" target="_blank"
                            rel="noopener noreferrer"><router-link to="/privacy-policy">Privacy
                                Policy</router-link></a>.</span>
                </template>
            </v-checkbox>
        </v-form>
    </div>
</template>

<script>
export default {
    name: "NewsletterSubscription",
    data() {
        return {
            sendingNewsletterForm: false,
            newsletterEmail: "",
            gdprConsent: false,
        };
    },
    computed: {
        isFormValid() {
            return this.newsletterEmail && this.gdprConsent;
        },
    },
    methods: {
        async submitForm() {
            this.valid = this.$refs.form.validate();
            if (!this.valid) {
                return;
            } else {
                this.sendingForm = true;
                try {
                    const response = await fetch('/.netlify/functions/send-email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name: 'name not obtained',
                            email: this.newsletterEmail,
                            message: "Newsletter subscription",
                        }),
                    });

                    if (response.ok) {
                        alert('Email sent successfully');
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.$refs.form.reset();
                    } else {
                        const errorData = await response.json();
                        console.error('Error data:', errorData);
                        alert('Error sending email');
                    }
                } catch (error) {
                    console.error('Fetch error:', error);
                    alert('Error sending email');
                } finally {
                    this.sendingForm = false;
                }
            }
        },
        required(value) {
            return !!value || "This field is required.";
        },
        validEmail(value) {
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return emailPattern.test(value) || "Invalid email.";
        },
    },
};
</script>

<style scoped>
.newsletter-subscription {
    padding: 64px 32px 80px;
    text-align: center;
    margin: 16px auto 0;
    width: 100vw;
    font-size: 19px !important;
}

.newsletter-subscription,
.newsletter-subscription>>>.v-input div div label {
    color: #0e2771 !important;
    font-size: 19px !important;
}

.headline {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #0e2771;
}

.subheadline {
    font-size: 19px;
    max-width: 1200px;
    margin: 0 auto 24px;
}
</style>
