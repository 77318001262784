<template>
    <div class="app-max-width" style="padding: 0 16px 64px;">
        <h1>DIGITAL BOOP. LTD</h1>
        <h2 style="margin-bottom: 32px;">Privacy Policy</h2>
        <ol>
            <li class="clause-heading">
                <p> How we respect privacy when dealing with personal information collected by our organisation</p>
                <p>
                    This Privacy Policy applies to information we DIGITAL BOOP. LTD collect about individuals who interact
                    with our organisation. It explains what personal information we collect and how we use it.
                </p>
                <p>
                    If you have any questions about this notice, please contact us at info@digital-boop.co.uk.
                </p>
                <p>
                    DIGITAL BOOP. LTD, SC764687
                </p>
            </li>
            <li class="clause-heading">
                <p>Personal data that we process</p>
                <p>
                    The following table explains the types of data we collect and the legal basis, under current data
                    protection legislation, on which this data is processed.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose</th>
                            <th>Data (key elements)</th>
                            <th>Legal basis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p class="normal-font-weight">
                                    Submitting a contact form
                                </p>
                            </td>
                            <td>
                                <p class="normal-font-weight">
                                    Name, email, phone number, any messages you send to us
                                </p>
                            </td>
                            <td>
                                <p class="normal-font-weight">
                                    Consent: you provide the data voluntarily to ask a question or request information
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="normal-font-weight">
                                    Marketing in newsletters
                                </p>
                            </td>
                            <td>
                                <p class="normal-font-weight">
                                    Name, email
                                </p>
                            </td>
                            <td>
                                <p class="normal-font-weight">
                                    Consent: you provide the data voluntarily to receive marketing content
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="normal-font-weight">
                                    Technical issues, feedback, partnership opportunities, general enquiries
                                </p>
                            </td>
                            <td>
                                <p class="normal-font-weight">
                                    Name, email, phone number, any messages you send to us
                                </p>
                            </td>
                            <td>
                                <p class="normal-font-weight">
                                    Consent: you provide the data voluntarily for the respective purposes
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
            <li class="clause-heading">
                <p>
                    How we use your data & when it may be required
                </p>
                <p>
                    We will only use your data in a manner that is appropriate considering the basis on which that data was
                    collected, as set out in the table at the top of this policy.
                </p>
                <p>
                    For example, we may use your personal information to:
                </p>
                <ul>
                    <li>
                        <p class="normal-font-weight">
                            reply to enquiries you send to us;
                        </p>
                    </li>
                    <li>
                        <p class="normal-font-weight">
                            send you newsletters and marketing materials;
                        </p>
                    </li>
                    <li>
                        <p class="normal-font-weight">
                            address technical issues, feedback, partnership opportunities, and general enquiries.
                        </p>
                    </li>
                </ul>
            </li>
            <li class="clause-heading">
                <p>When we share your data</p>
                <p>
                    We will only pass your data to third parties in the following circumstances:
                </p>
                <ul>
                    <li>
                        <p class="normal-font-weight">
                            we have your explicit consent to share the data;
                        </p>
                    </li>
                    <li>
                        <p class="normal-font-weight">
                            we are required by law to share your data.
                        </p>
                    </li>
                </ul>
                <p>
                    In addition, we will only pass data to third parties outside of the EU where appropriate safeguards are
                    in place as defined by Article 46 of the General Data Protection Regulation.
                </p>
            </li>
            <li class="clause-heading">
                <p>How long we keep your data</p>
                <p>
                    We take the principles of data minimisation and removal seriously and we only ever ask for the minimum
                    amount of data for the associated purpose and delete that data promptly, once it is no longer required.
                </p>
                <p>
                    Where data is collected on the basis of consent, we will seek renewal of consent at least every 12
                    months.
                </p>
            </li>
            <li class="clause-heading">
                <p>Rights you have over your data</p>
                <p>
                    You have a range of rights over your data, which include the following:
                </p>
                <ul>
                    <li>
                        <p class="normal-font-weight">
                            Where data processing is based on consent, you may revoke this consent at any time by contacting
                            us;
                        </p>
                    </li>
                    <li>
                        <p class="normal-font-weight">
                            You have the right to ask for rectification and/or deletion of your information;
                        </p>
                    </li>
                    <li>
                        <p class="normal-font-weight">
                            You have the right of access to your information;
                        </p>
                    </li>
                    <li>
                        <p class="normal-font-weight">
                            You have the right to lodge a complaint with the Information Commissioner if you feel your
                            rights have been infringed.
                        </p>
                    </li>
                </ul>
                <p>
                    A full summary of your legal rights over your data can be found on the Information Commissioner’s
                    website here: https://ico.org.uk/
                </p>
                <p>
                    If you would like to access the rights listed above, or any other legal rights you have over your data
                    under current legislation, please get in touch with us.
                </p>
                <p>
                    Please note that relying on some of these rights, such as the right to delete your data, will make it
                    impossible for us to continue to deliver some services to you.
                </p>
            </li>
            <li class="clause-heading">
                <p>Cookies & usage tracking</p>
                <p>
                    A cookie is a small file of letters and numbers that is downloaded on to your computer when you visit a
                    website. Cookies are used by many websites and can do a number of things, like remembering your
                    preferences, recording field entries, and counting the number of people looking at a website.
                </p>
                <p>
                    We do not use cookies to collect personal data beyond the purposes in section 1 above. However, we could
                    use some cookies that do not collect personal information but that do help us collect anonymous
                    information about how people use our website. This helps us to improve the way our website works, for
                    example by ensuring that users are finding what they are looking for easily.
                </p>
            </li>
            <li class="clause-heading">
                <p>
                    Changes to this policy
                </p>
                <p>
                    We may update this policy from time to time. We will notify you of any changes by posting the new policy
                    on this page.
                </p>
            </li>
        </ol>
        <v-btn class="primary-cta">
            <router-link to="/">Back to Home</router-link>
        </v-btn>

    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
    methods: {
        goBack() {
            this.$router.push('/');
        },
    },
};
</script>

<style scoped>
ol {
    margin-left: 16px;
}

.clause-heading {
    margin-bottom: 16px;
}

.clause-heading p:first-of-type {
    font-weight: bold;
}

.normal-font-weight {
    font-weight: normal !important;
}

ul {
    margin-left: 32px;
}

h1 {
    padding-bottom: 16px;
}

.primary-cta a {
    color: #ffffff;
    text-decoration: none;
}
</style>
