<template>
    <v-main class="gradient-background custom-main">
        <router-view></router-view>
    </v-main>
</template>

<script>
export default {
    name: 'AppMain'
}
</script>
<style scoped>
@media only screen and (max-width: 375px) {
      .gradient-background {
    padding: 0 !important;
  }
}
</style> 