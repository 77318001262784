<template>
  <span>
    <v-container fluid class="home-container"
      style="align-items: center; margin: auto; background-image: url('/hero-banner.png'); background-repeat: no-repeat; background-size: cover;">
      <v-row class="display: flex; align-items: center; margin: auto;">
        <v-col class="centered-text">
          <h1 class="hero-text-heading">
            <b>Creative Clicks, Endless Possibilities</b>
          </h1>
          <v-spacer class="my-10"></v-spacer>
          <p class="" style="color: #ffffff; font-size:19px; max-width: 700px; margin: 16px auto;">
            Ready to take your digital presence to the next level? Let's chat! Click the button below to
            schedule a
            consultation and learn how <b>digital boop.</b> can help your business thrive.
          </p>
          <v-btn dark class="mt-3 primary-cta" href="/contact">
            <b>SCHEDULE A CONSULTATION</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="my-10"></v-divider>
    <v-container fluid class="pain-points-container" style="max-width: 1200px !important">
      <h2 class="section-title">How can <b>digital boop.</b> help you?</h2>
      <v-container fluid>
        <v-row>
          <v-col :cols="12" md="6" style="backgroundImage: url('/1.png') " class="row-background">
          </v-col>
          <v-col :cols="12" md="6" style="padding: 0;">
            <div class="row-background" style="backgroundColor: #1bf2bc">
              <div class="row-content">
                <h3 class="row-title" style="color: #0e2771">Effective Web Design</h3>
                <p class="row-description" style="color: #0e2771">Attract and engage visitors with a website that
                  showcases your business and converts leads into customers.</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="row-content-reverse">
          <v-col :cols="12" md="6" style="padding: 0;">
            <div class="row-background" style="backgroundColor: #f4e3f2">
              <div class="row-content">
                <h3 class="row-title" style="color: #2554e4">Creative Content</h3>
                <p class="row-description" style="color: #2554e4">Stand out from the competition with fresh and engaging
                  content that tells your brand story and connects with your target audience.</p>
              </div>
            </div>
          </v-col>
          <v-col :cols="12" md="6" style="backgroundImage: url('/2.png') " class="row-background">
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" md="6" style="backgroundImage: url('/3.png') " class="row-background">
          </v-col>
          <v-col :cols="12" md="6" style="padding: 0;">
            <div class="row-background" style="backgroundColor: #2554e4">
              <div class="row-content">
                <h3 class="row-title" style="color: #ffffff">Social Media Marketing</h3>
                <p class="row-description" style="color: #ffffff">Build a loyal following and increase brand awareness
                  with custom social media marketing strategies that speak directly to your audience.</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid
      style="width: 100vw !important; background-color: #f4e3f2; display: flex; flex-directon: column; margin: 0 auto;">
      <v-row class="contact-message" ref="contactSection">
        <v-col cols="12" class="contact-message-col">
          <h2 style="color: #2554e4; font-size: 48px; text-align: center; font-weight: normal">Contact <b>digital
              boop.</b></h2>
          <p style="text-align: center; margin-top:16px; font-size: 19px">At <b>digital boop.</b> we know that
            transforming your business can
            feel
            overwhelming.
            But
            it doesn't have to be! With our simple form, you can take the first step towards success in just a
            few
            minutes.</p>
          <p style="text-align: center; font-size: 19px">
            So don't wait - fill out our simple form today and let's work together to transform your business.
            It's
            the
            first step towards achieving your goals and taking your business to the next level.
          </p>
          <v-btn dark class="primary-cta" href="/contact" style="margin:  32px auto">
            <b>GET IN TOUCH</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <newsletter-subscription></newsletter-subscription>
  </span>
</template>
<script>
import '@/appStyles.css';
import NewsletterSubscription from "@/components/NewsletterSubscription.vue";
// import ReCaptcha from '@/components/ReCaptcha.vue'
export default {
  name: 'Home',
  data() {
    return {
      dialog: false,
      sendingNewsletterForm: false,
      sendingForm: false,
      newsletterName: '',
      newsletterEmail: '',
      name: '',
      email: '',
      message: '',
      gdprConsent: false,
    };
  },
  components: {
    NewsletterSubscription,
  },
  methods: {
    async submitForm() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        return;
      } else {
        this.sendingForm = true;
        try {
          const response = await fetch('/.netlify/functions/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: this.name,
              email: this.email,
              message: this.message,
            }),
          });

          if (response.ok) {
            alert('Email sent successfully');
            this.name = '';
            this.email = '';
            this.message = '';
            this.$refs.form.reset();
          } else {
            const errorData = await response.json();
            console.error('Error data:', errorData);
            alert('Error sending email');
          }
        } catch (error) {
          console.error('Fetch error:', error);
          alert('Error sending email');
        } finally {
          this.sendingForm = false;
        }
      }
    },
    // async submitNewsletterForm() {
    //   // Add your logic for submitting the newsletter form and sending the ebook by email
    //   console.log("Newsletter form submitted.");
    // },
    // openDialog() {
    //   this.dialog = true;
    // },
    required(value) {
      return !!value || "This field is required.";
    },
    validEmail(value) {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return emailPattern.test(value) || "Invalid email.";
    },
    setCardHeights() {
      this.$nextTick(() => {
        const cards = [this.$refs.card1, this.$refs.card2, this.$refs.card3];
        const heights = cards
          .filter((card) => card) // Add this line to filter out undefined cards
          .map((card) => card.$el.offsetHeight);
        const maxHeight = Math.max(...heights);
        cards.forEach((card) => {
          if (card) { // Add this line to check if the card exists before setting its height
            card.$el.style.height = `${maxHeight}px`;
          }
        });
      });
    },
  },
  computed: {
    isFormValid() {
      return this.name && this.email && this.message && this.gdprConsent;
    },
  },
  mounted() {
    this.setCardHeights();
    window.addEventListener("resize", this.setCardHeights);
    document.dispatchEvent(new Event('app.rendered'));
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setCardHeights);
  },
};
</script>
<style scoped>
.centered-text {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  text-align: center;
  margin: 64px auto;
}

.contact-message-col {
  padding-bottom: 32px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.contact-message {
  max-width: 1200px; margin: 64px auto;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.6;
}
.hero-text-heading {
  color: #ffffff; font-size:4rem; font-weight: normal; margin: 0 32px;
}
.pain-points-container {
  padding: 32px 16px 16px;
}

.banner-ad-col {
  padding: 0;
}

.row-background {
  height: auto;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.row-content {
  padding: 24px;
  max-width: 450px;
  margin: auto !important;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.row-title {
  font-size: 40px;
  margin-bottom: 12px;
}

.row-description {
  font-size: 19px;
  color: #333;
  max-width: 500px;
}
@media only screen and (max-width: 959px) {
  .row-content-reverse {
    flex-wrap: wrap-reverse;
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 600px) {
  .row-background {
    height: auto;
  }

  .row-content {
    padding: 16px;
  }
}
@media (max-width: 412px) {
  .home-container {
    width: 100vw;
    padding: 32px;
  }
  .hero-text-heading {
  font-size:48px;
}
.centered-text {
  margin: 32px auto;
}
.contact-message {
  margin: 0 auto !important;
}
.row-content {
  height: auto !important;
}
}
</style>

<!-- <v-row class="text-center">
        <v-col class="banner-ad-col">
          <v-dialog v-model="dialog" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <img src="@/assets/web-banner-ad.png" alt="Banner Ad" height="auto" width="100%" class="banner-ad"
                v-bind="attrs" v-on="on" />
            </template>
            <v-card>
              <v-card-title class="headline">Sign up for our newsletter</v-card-title>
              <v-card-text>
                Enter your details to get a free ebook and receive updates from us.
                <v-form @submit.prevent="submitNewsletterForm" ref="newsletterForm">
                  <v-text-field v-model="newsletterName" label="Name" required :rules="[required]"></v-text-field>
                  <v-text-field v-model="newsletterEmail" label="Email" required type="email"
                    :rules="[required, validEmail]"></v-text-field>
                  <v-btn type="submit" color="primary" :loading="sendingNewsletterForm"
                    :disabled="sendingNewsletterForm">Submit</v-btn>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row> -->


      <!-- <v-col cols="12" md="8" style="padding: 0;">
          <v-card flat class="contact-form">
            <v-form id="contact-form" name="contact-boop" class="form-style" @submit.prevent="submitForm" ref="form">
              <v-text-field v-model="name" label="Name" required :rules="[required]"></v-text-field>
              <v-text-field v-model="email" label="Email" required type="email"
                :rules="[required, validEmail]"></v-text-field>
              <v-textarea v-model="message" label="Message" required :rules="[required]"></v-textarea>
              <v-checkbox v-model="gdprConsent" required :rules="[required]">
                <template v-slot:label>
                  <span style="margin-left: 8px">I have read and understood the <a target="_blank"
                      rel="noopener noreferrer"><router-link to="/privacy-policy">Privacy
                        Policy</router-link></a>.</span>
                </template>
              </v-checkbox>
              <div class="recaptcha-container">
                <v-btn dark type="submit" color="primary" class="mt-3 primary-cta" :loading="sendingForm"
                  :class="{ 'disabled-button': !isFormValid }" @click="submitForm">
                  <b>Submit</b>
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col> -->