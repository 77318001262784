<template>
    <v-btn :class="customClass" :color="color" :text="text" :href="href" :dark="dark" @click="$emit('click')">
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    props: {
        customClass: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        text: {
            type: Boolean,
            default: false
        },
        href: {
            type: String,
            default: ''
        },
        dark: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped></style>