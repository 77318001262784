<template>
    <div class="about-us-page app-max-width">
        <v-container fluid style="margin-bottom: 32px">
            <v-row>
                <v-col>
                    <h1 class="page-title">About Us</h1>
                    <p class="tagline" style="font-size: 18px; font-weight: bold; margin-bottom: 16px;">
                        Creative Clicks, Endless Possibilities
                    </p>
                    <p>
                        <b>digital boop.</b> is a digital marketing and design agency that specializes in web design and
                        development, SEO, web
                        content creation, logo design, social media marketing services, copywriting, and graphic design. Our
                        mission
                        is to help businesses of all sizes enhance their online presence and achieve their marketing goals.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="my-10"></v-divider>
        <v-container fluid>

            <h2 class="section-title">Our Vision & Values</h2>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card flat class="vision-value-card">
                        <v-icon class="vision-value-icon">mdi-eye</v-icon>
                        <h3 class="vision-value-title">Vision Statement</h3>
                        <p class="vision-value-description">At <b>digital boop.</b>, we envision a world where every business can
                            harness the power of digital marketing
                            to reach
                            new heights. We are committed to providing innovative solutions that deliver results, while
                            maintaining a
                            strong focus on customer satisfaction.</p>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card flat class="vision-value-card">
                        <v-icon class="vision-value-icon">mdi-star</v-icon>
                        <h3 class="vision-value-title">Our Values</h3>
                        <p class="vision-value-description">
                        <ul>
                            <li><b>Integrity:</b> We maintain the highest ethical standards in everything we do.</li>
                            <li><b>Innovation:</b> We constantly strive to improve our services and stay ahead of industry
                                trends.</li>
                            <li><b>Collaboration:</b> We believe in the power of teamwork and open communication to achieve
                                the
                                best results for our clients.</li>
                            <li><b>Accountability:</b> We take ownership of our work and always deliver on our promises.
                            </li>
                        </ul>
                        </p>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="my-10"></v-divider>
        <v-container>
            <v-row class="contact-message" ref="contactSection" style="margin-top: 16px">
                <v-col cols="12" md="4" class="contact-message-col">
                    <h2 style="color: #2554e4; font-size: 32px; font-weight: normal">Contact <b>boop.</b></h2>
                    <p style="margin-top:16px;">At <b>digital boop.</b> we know that transforming your business can feel
                        overwhelming.
                        But
                        it doesn't have to be! With our simple form, you can take the first step towards success in just a
                        few
                        minutes.</p>
                    <p>
                        So don't wait - fill out our simple form today and let's work together to transform your business.
                        It's
                        the
                        first step towards achieving your goals and taking your business to the next level.
                    </p>
                </v-col>
                <v-col cols="12" md="8" style="padding: 0;">
                    <v-card flat class="contact-form">
                        <v-form id="contact-form" name="contact-boop" class="form-style" @submit.prevent="submitForm"
                            ref="form">
                            <v-text-field v-model="name" label="Name" required :rules="[required]"></v-text-field>
                            <v-text-field v-model="email" label="Email" required type="email"
                                :rules="[required, validEmail]"></v-text-field>
                            <v-textarea v-model="message" label="Message" required :rules="[required]"></v-textarea>
                            <v-checkbox v-model="gdprConsent" required :rules="[required]">
                                <template v-slot:label>
                                    <span style="margin-left: 8px">I have read and understood the <a target="_blank"
                                            rel="noopener noreferrer"><router-link to="/privacy-policy">Privacy
                                                Policy</router-link></a>.</span>
                                </template>
                            </v-checkbox>
                            <div class="recaptcha-container">
                                <v-btn dark type="submit" color="primary" class="mt-3 primary-cta" :loading="sendingForm"
                                    :class="{ 'disabled-button': !isFormValid }" @click="submitForm">
                                    <b>Submit</b>
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
export default {
    name: "AboutUs",
    data() {
        return {
            sendingForm: false,
            name: '',
            email: '',
            message: '',
            gdprConsent: false,
        };
    },
    methods: {
        async submitForm() {
            this.valid = this.$refs.form.validate();
            if (!this.valid) {
                return;
            } else {
                this.sendingForm = true;
                try {
                    const response = await fetch('/.netlify/functions/send-email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name: this.name,
                            email: this.email,
                            message: this.message,
                        }),
                    });

                    if (response.ok) {
                        alert('Email sent successfully');
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.$refs.form.reset();
                    } else {
                        const errorData = await response.json();
                        console.error('Error data:', errorData);
                        alert('Error sending email');
                    }
                } catch (error) {
                    console.error('Fetch error:', error);
                    alert('Error sending email');
                } finally {
                    this.sendingForm = false;
                }
            }
        },
        required(value) {
            return !!value || "This field is required.";
        },
        validEmail(value) {
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return emailPattern.test(value) || "Invalid email.";
        },
    },
    computed: {
        isFormValid() {
            return this.name && this.email && this.message && this.gdprConsent;
        },
    },
};
</script>

<style scoped>
.about-us-page {
    padding: 2rem 0;
}

.page-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--brand-primary-color);
}

.section-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.tagline {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: var(--brand-primary-color);
}

.text-left {
    margin-left: 8px !important;
}

.vision-value-card {
    padding: 24px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}
.vision-value-card ul {
    margin-left: 24px;
}

.vision-value-icon {
    font-size: 48px;
    color: #2554e4;
    margin-bottom: 16px;
}

.vision-value-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.vision-value-description {
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
}</style>

