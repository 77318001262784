<template>
  <v-app-bar color="#ffffff" light app flat style="height: 80px" class="boop-toolbar">
    <span class="app-max-width" style="display: flex; flex-direction: row; margin: auto;
                              justify-content: space-between;
                              width: 100%;
                              
                              align-items: center;">
      <v-toolbar-title style="">
        <router-link to="/" style="height: 100%; display:flex;">
          <img src="@/assets/digital-boop-long-format-logo-blue.svg" alt="Boop Logo" class="boop-logo">
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="nav-bar-icon"></v-app-bar-nav-icon>
      <v-navigation-drawer temporary absolute light v-model="drawer" style="height: auto;">
        <img src="@/assets/digital-boop-long-format-logo-green.svg" alt="Boop Logo" class="boop-logo-navigation-drawer">
        <v-divider class="my-10"></v-divider>
        <v-list>
          <v-list-item v-for="(route, index) in routes" :key="index">
            <v-list-item-content>
              <router-link :to="route.path" @click.native="drawer = !drawer">{{ route.name
              }}</router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn icon @click="openFacebook">
                <v-icon color="var(--brand-primary-color)">mdi-facebook</v-icon>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="desktop-nav">
        <router-link class="nav-link-style" text v-if="$vuetify.breakpoint.smAndUp" :to="'/'">
          <span>Home</span>
        </router-link>
        <router-link class="nav-link-style" text v-if="$vuetify.breakpoint.smAndUp" :to="'/about'">
          <span>About</span>
        </router-link>
        <router-link class="nav-link-style" text v-if="$vuetify.breakpoint.smAndUp" :to="'/contact'">
          <span>Get in touch</span>
        </router-link>
        <v-btn icon @click="openFacebook" v-if="$vuetify.breakpoint.smAndUp">
          <v-icon color="var(--brand-primary-color)">mdi-facebook</v-icon>
        </v-btn>
      </div>
    </span>
  </v-app-bar>
</template>

<script>
import { openFacebook } from "@/helpers.js";
import BoopButton from "@/components/BoopButton.vue";
export default {
  name: "AppHeader",
  components: {
    BoopButton,
  },
  data() {
    return {
      drawer: null,
      routes: [
        { name: "Home", path: "/" },
        { name: "About", path: "/about" },
        { name: "Get in touch", path: "/contact" },
      ],
    };
  },
  methods: {
    openFacebook,
  },
};
</script>

<style scoped>
.boop-logo {
  /* margin-top: 8px; */
  /* height: 150px; */
  width: 200px;
  margin: auto 0;
}
.boop-logo-navigation-drawer {
  width: 100%;
  margin: auto;
  padding: 16px
}

.nav-link-style {
  color: #0e2771;
  text-decoration: none;
  padding: 0 16px;
  font-weight: bold;
  /* padding: 6px 16px;
  border-radius: 24px;
  margin-right: 10px; */
}

.nav-link-style:hover {
  text-decoration: underline;
}

.nav-bar-icon {
  display: none !important;
  color: var(--brand-primary-color) !important;
  border: thin solid var(--brand-primary-color);
}

.boop-toolbar>>>.v-toolbar__content {
  height: 100% !important;
}
/* @media only screen and (max-width: 745px) {
    .boop-logo {
    width: 300px;
  }
}
@media only screen and (max-width: 695px) {
    .boop-logo {
    width: 250px;
  }
} */
@media only screen and (max-width: 695px) {
    .desktop-nav {
    display: none;
  }
   .nav-bar-icon {
    display: flex !important;
  }

  .nav-link-style {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .boop-logo {
    width: 90%;
  }
}</style>
