<template>
    <v-container fluid style="max-width: 1200px !important; margin-top: 16px;">
        <v-row class="text-center">
            <v-col cols="12" md="4" class="contact-message-col">
                <h2 style="color: #2554e4; font-size: 32px; font-weight: normal">Contact <b>digital boop.</b></h2>
                <p style="margin-top:16px;">At <b>digital boop.</b> we know that transforming your business can feel
                    overwhelming.
                    But
                    it doesn't have to be! With our simple form, you can take the first step towards success in just a
                    few
                    minutes.</p>
                <p>
                    So don't wait - fill out our simple form today and let's work together to transform your business.
                    It's
                    the
                    first step towards achieving your goals and taking your business to the next level.
                </p>
            </v-col>
            <v-col cols="12" md="8" style="padding: 0;">
                <v-card flat class="contact-form">
                    <v-form id="contact-form" name="contact-boop" class="form-style" @submit.prevent="submitForm"
                        ref="form">
                        <v-text-field v-model="name" label="Name" required :rules="[required]"></v-text-field>
                        <v-text-field v-model="email" label="Email" required type="email"
                            :rules="[required, validEmail]"></v-text-field>
                        <v-textarea v-model="message" label="Message" required :rules="[required]"></v-textarea>
                        <v-checkbox v-model="gdprConsent" required :rules="[required]">
                            <template v-slot:label>
                                <span style="margin-left: 8px">I have read and understood the <a target="_blank"
                                        rel="noopener noreferrer"><router-link to="/privacy-policy">Privacy
                                            Policy</router-link></a>.</span>
                            </template>
                        </v-checkbox>
                        <div class="recaptcha-container">
                            <v-btn dark type="submit" color="primary" class="mt-3 primary-cta" :loading="sendingForm"
                                :class="{ 'disabled-button': !isFormValid }" @click="submitForm">
                                <b>Submit</b>
                            </v-btn>
                        </div>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Contact',
    data() {
        return {
            sendingForm: false,
            name: '',
            email: '',
            message: '',
            gdprConsent: false,
        };
    },
    computed: {
        isFormValid() {
            return this.name && this.email && this.message && this.gdprConsent;
        },
    },
    methods: {
       async submitForm() {
            this.valid = this.$refs.form.validate();
            if (!this.valid) {
                return;
            } else {
                this.sendingForm = true;
                try {
                    const response = await fetch('/.netlify/functions/send-email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name: this.name,
                            email: this.email,
                            message: this.message,
                        }),
                    });

                    if (response.ok) {
                        alert('Email sent successfully');
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.$refs.form.reset();
                    } else {
                        const errorData = await response.json();
                        console.error('Error data:', errorData);
                        alert('Error sending email');
                    }
                } catch (error) {
                    console.error('Fetch error:', error);
                    alert('Error sending email');
                } finally {
                    this.sendingForm = false;
                }
            }
        },
        required(value) {
            return !!value || "This field is required.";
        },
        validEmail(value) {
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return emailPattern.test(value) || "Invalid email.";
        },
    },
};
</script>
<style scoped>
.contact-message-col {
    padding-bottom: 32px;
}

.disabled-button {
    pointer-events: none;
    opacity: 0.6;
}
</style>