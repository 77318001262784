import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.css';
import '@mdi/font/css/materialdesignicons.css';
import router from './router';

Vue.config.productionTip = false;

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {}, // Customize your theme here
  icons: {
    iconfont: 'mdi',
  },
});
new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')
